import React from "react";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb";
import SelectMenu from "../components/virtual-sample-page/select-menu";
import ProgressStepper from "../components/virtual-sample-page/progress-stepper";
import RequestForm from "../components/virtual-sample-page/request-form";
import InfoDialog from "../components/info-dialog";
import axios from "axios";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { graphql } from "gatsby";
import { GQLProduct, GQLQuery } from "../types";
import GlobalConstants, {
  VirtualSamplePage as Constants,
  VirtualSampleDropdownState,
  VirtualSampleFormState,
  CloudBaseURL,
  InfoDialog as InfoDialogConstants,
} from "../helpers/constants";

const UseStyles = makeStyles({
  header: {
    backgroundColor: "#2b2b2b",
    color: "#ffffff",
    padding: "0.5rem 1rem 0.5rem 1rem",
  },
});

type VirtualSamplePageProps = {
  data: {
    crystallize: GQLQuery;
  };
};

/*
 * data -> Crystallize GQL query result
 */
const VirtualSamplePage = (props: VirtualSamplePageProps): JSX.Element => {
  const PathObj = GetSimplePathObj(GlobalConstants.VirtualSample);
  const QueryData = props.data.crystallize.catalogue;
  const ExcludedFolders = ["Articles", "Slideshow"];
  const Classes = UseStyles();
  const BrandFolders = QueryData.children.filter(
    (folder) => !ExcludedFolders.includes(folder.name)
  );

  /* Updated from Brandbox to only show Nalgene */
  const Brands = [{ id: "5f91d2c5e8f6e26308f746ec", name: "Nalgene" }];

  /* Progress stepper state */
  const [ProgressState, SetProgressState] = React.useState(0);

  /* Brand select menu state */
  const InitBrandState: VirtualSampleDropdownState = {
    label: "Select Brand",
    value: "",
    valueAsText: "",
    options: Brands,
    required: true,
    disabled: false,
  };
  const [BrandState, SetBrandState] = React.useState(InitBrandState);

  /* Product select menu state */
  const InitProductState: VirtualSampleDropdownState = {
    label: "Select Product",
    value: "",
    valueAsText: "",
    options: [],
    required: true,
    disabled: true,
  };
  const [ProductState, SetProductState] = React.useState(InitProductState);

  /* Virtual sample request form state */
  const InitFormState = {
    name: "",
    email: "",
    company: "",
    asiNumber: "",
    phoneNumber: "",
    designNotes: "",
    formIsValid: true,
    hasValidSelection: false,
  };
  const [FormState, SetFormState] = React.useState(InitFormState);

  /* State variable/fn for customer supplied image artwork */
  const [ImageState, SetImageState] = React.useState([]);
  const OnImageStateChange = (newFiles) => SetImageState(newFiles);

  /* State variable/fn for virtual sample dialog window */
  const [InfoDialogState, SetInfoDialogState] = React.useState({
    open: false,
    title: "",
    message: "",
  });

  /* Updates the progress stepper to show the user what to do next */
  const UpdateStepper = (value?: number) => {
    if (value) {
      SetProgressState(value);
    } else {
      if (BrandState.value.length > 0) {
        SetProgressState(1);
      }
      if (ProductState.value.length > 0) {
        SetProgressState(2);
      }
    }
  };

  /* Event handler - called whenever the user changes any of the input fields */
  const OnFormChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const CurrentTarget = event.currentTarget;
    SetFormState((prevState) => ({
      ...prevState,
      [CurrentTarget.name]: CurrentTarget.value,
    }));
  };

  /* Closes the virtual sample dialog */
  const HandleCloseInfoDialog = () => {
    SetInfoDialogState((infoDialogState) => ({
      ...infoDialogState,
      open: false,
    }));
  };

  /* Event handler for changes (selections) to the brand and product select menus */
  const OnSelectBrandProduct = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    child: React.ReactNode,
    setState: React.Dispatch<React.SetStateAction<VirtualSampleDropdownState>>
  ) => {
    const Value = event.target.value.toString();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ValueAsText: string = (child as any).props.children.props.children;

    setState((prevState) => {
      /* If the user selected a brand, update the products dropwdown choices */
      if (prevState.label === "Select Brand") {
        const MatchingFolders = BrandFolders.filter(
          (folder) => folder.id === Value
        );
        const MatchingProducts = (MatchingFolders[0]
          .children as GQLProduct[]).map((product) => ({
          id: product.id,
          name: product.name,
          defaultImages: product.defaultVariant.images,
        }));

        /* Updates the products dropdown choices */
        SetProductState((prevState) => ({
          ...prevState,
          options: MatchingProducts,
          value: "",
          valueAsText: "",
          disabled: false,
        }));
        /* Update form state to indicate a valid selection is NOT present */
        SetFormState((prevState) => ({
          ...prevState,
          hasValidSelection: false,
        }));
        /* Rewind a step in the progress stepper - any previously selected product will be cleared */
        UpdateStepper(1);
      } else if (Value.length > 0) {
        /* Update form state to indicate a valid selection is present */
        SetFormState((prevState) => ({
          ...prevState,
          hasValidSelection: true,
        }));
        /* Product has been selected - advance the progress stepper */
        UpdateStepper(2);
      }

      /* This is what actually updates the state for the brand/product dropdown */
      return {
        ...prevState,
        value: Value ?? "",
        valueAsText: ValueAsText ?? "",
      };
    });
  };

  /* React event handler called when the virtual sample request is submitted */
  const OnSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    validateForm: (formState: VirtualSampleFormState) => boolean
  ): void => {
    event.preventDefault();
    const FormIsValid = validateForm(FormState);

    /* If the form is valid, update the progress stepper */
    FormIsValid ? SetProgressState(3) : null;
    /* Update the form with the new valid status so it can be used elsewhere */
    SetFormState((prevState) => ({
      ...prevState,
      formIsValid: validateForm(FormState),
    }));

    /* Prepare form data object to send with the HTTP request */
    const Data = new FormData();
    Data.append("product", ProductState.valueAsText);
    Data.append("optionsState", JSON.stringify({}));
    Data.append("formState", JSON.stringify(FormState));
    Data.append("image", ImageState[0]);

    axios
      .post(CloudBaseURL + "/virtual-sample", Data)
      .then(() => {
        /* Resets the form state and clears input fields */
        SetFormState(InitFormState);
        /* Displays success dialog*/
        SetInfoDialogState({
          open: true,
          title: InfoDialogConstants.SuccessTitle,
          message: InfoDialogConstants.SuccessMessage,
        });
      })
      .catch(() => {
        /* Display error dialog */
        SetInfoDialogState({
          open: true,
          title: InfoDialogConstants.FailureTitle,
          message: InfoDialogConstants.FailureMessage,
        });
      });
  };

  return (
    <Layout>
      <Breadcrumb pathObj={PathObj} />
      <ProgressStepper activeStep={ProgressState} steps={Constants.Steps} />
      <Grid item xs={12}>
        <div className={Classes.header}>
          <Typography variant="button">Product Selection</Typography>
        </div>
      </Grid>
      <SelectMenu
        state={BrandState}
        onChange={OnSelectBrandProduct}
        setState={SetBrandState}
      />
      <SelectMenu
        state={ProductState}
        onChange={OnSelectBrandProduct}
        setState={SetProductState}
      />
      <RequestForm
        productState={ProductState}
        formState={FormState}
        onFormChange={OnFormChange}
        onSubmit={OnSubmit}
        onImageStateChange={OnImageStateChange}
      />
      <InfoDialog
        open={InfoDialogState.open}
        onClose={HandleCloseInfoDialog}
        title={InfoDialogState.title}
        message={InfoDialogState.message}
      />
    </Layout>
  );
};

export const Query = graphql`
  query {
    crystallize {
      catalogue {
        children {
          id
          name
          children {
            ... on CRYSTALLIZE_Product {
              id
              name
              path
              defaultVariant {
                images {
                  url
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default VirtualSamplePage;
